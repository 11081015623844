import React from 'react';
import {
  makeStyles,
  Theme,
  Grid,
  Paper,
  Button,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';

import { catalogApiRef } from '@backstage/plugin-catalog-react';

import { Content, Header, Page } from '@backstage/core-components';

import { useApi } from '@backstage/core-plugin-api';
import { CustomCatalogClient } from '../../clients/CustomCatalogClient';

export const AdminPage = () => {
  const catalogApi = useApi(catalogApiRef);
  // eslint-disable-next-line no-console
  const refreshGCP = async () => {
    catalogApi
      // @ts-expect-error
      .refeshGCPProviders()
      // eslint-disable-next-line no-console
      .catch(console.error);
    // eslint-disable-next-line no-alert
    alert('refreshing');
  };

  const refreshGithub = async () => {
    catalogApi
      // @ts-expect-error
      .refeshGithubProviders()
      .then(() => {})
      // eslint-disable-next-line no-console
      .catch(console.error);
    // eslint-disable-next-line no-alert
    alert('refreshing');
  };

  return (
    <Page themeId="home">
      <Header title="Admin" />
      <Content>
        <Grid container direction="row">
          <Grid item xs={4}>
            <Card>
              <CardHeader title="GCP" subheader="Force GCP entities refresh" />
              <CardContent>
                <Button onClick={refreshGCP} variant="contained">
                  Refresh GCP Entities
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card>
              <CardHeader
                title="Github"
                subheader="Force Github entities refresh"
              />
              <CardContent>
                <Button onClick={refreshGithub} variant="contained">
                  Refresh Github Entities
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
