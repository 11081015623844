// responsible to fetch data from /tech-radar/data.json
import {
  RadarEntry,
  RadarQuadrant,
  RadarRing,
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@backstage/plugin-tech-radar';

const rings = new Array<RadarRing>();
rings.push({ id: 'ADOPT', name: 'ADOPT', color: '#93c47d' });
rings.push({ id: 'TRIAL', name: 'TRIAL', color: '#93d2c2' });
rings.push({ id: 'ASSESS', name: 'ASSESS', color: '#fbdb84' });
rings.push({ id: 'HOLD', name: 'HOLD', color: '#efafa9' });

import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';

export class SendinblueTechRadarApi implements TechRadarApi {
  constructor(
    private config: { fetchApi: FetchApi; discoveryApi: DiscoveryApi },
  ) {}
  async load(): Promise<TechRadarLoaderResponse> {
    const backendUrl = await this.config.discoveryApi.getBaseUrl('tech-radar');
    const response = await this.config.fetchApi.fetch(
      `${backendUrl}/data.json`,
    );
    const [_headers, ...data] = (await response.json()) as any[];
    const entries = new Array<RadarEntry>();
    const quadrants = new Array<RadarQuadrant>();
    // const rings = new Array<RadarRing>();
    const ringsIds = new Set(rings.map(ring => ring.id));
    data.forEach((entry, index) => {
      if (ringsIds.has(entry[2].toUpperCase())) {
        const entryQuadrant = entry[0];
        if (!quadrants.some(quadrant => quadrant.id === entryQuadrant)) {
          quadrants.push({ id: entryQuadrant, name: entryQuadrant });
        }
        entries.push({
          id: index.toString(),
          key: entry[1],
          quadrant: entryQuadrant,
          timeline: [
            {
              ringId: entry[2].toUpperCase(),
              date: new Date(),
              // description: 'long description',
            },
          ],
          title: entry[1],
          url: entry[3],
          links: [
            {
              url: entry[3],
              title: 'Learn more',
            },
          ],
          description: entry[4],
        });
      }
    });
    return { entries, quadrants, rings };
  }
}

// Keep as an example
// const entries = new Array<RadarEntry>();
// entries.push({
//   timeline: [
//     {
//       moved: 0,
//       ringId: 'adopt',
//       date: new Date('2020-08-06'),
//       description:
//         'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
//     },
//   ],
//   url: '#',
//   key: 'javascript',
//   id: 'javascript',
//   title: 'JavaScript',
//   quadrant: 'languages',
//   description:
//     'Excepteur **sint** occaecat *cupidatat* non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\n\n```ts\nconst x = "3";\n```\n',
// });
