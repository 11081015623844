/**
 * Custom page to get the radar adjust to the screen
 */
import {
  Content,
  ContentHeader,
  Header,
  Page,
} from '@backstage/core-components';
import {
  TechRadarComponentProps,
  TechRadarComponent,
} from '@backstage/plugin-tech-radar';
import { Grid, Input, makeStyles } from '@material-ui/core';
import React, { useLayoutEffect, useRef, useState } from 'react';

const useStyles = makeStyles(() => ({
  overflowXScroll: {
    overflowX: 'scroll',
  },
}));

/**
 * Properties for {@link TechRadarPage}
 *
 * @public
 */
export interface TechRadarPageProps
  extends Omit<TechRadarComponentProps, 'width' | 'height'> {
  /**
   * Title
   */
  title?: string;
  /**
   * Subtitle
   */
  subtitle?: string;
  /**
   * Page Title
   */
  pageTitle?: string;
}

/**
 * Main Page of Tech Radar
 *
 * @public
 */
export function TechRadarPage(props: TechRadarPageProps) {
  const {
    title = 'Tech Radar',
    subtitle = 'Pick the recommended technologies for your projects',
    pageTitle = 'Company Radar',
    ...componentProps
  } = props;
  const classes = useStyles();
  const [searchText, setSearchText] = React.useState('');

  return (
    <Page themeId="tool">
      <Header title={title} subtitle={subtitle} />
      <Content className={classes.overflowXScroll}>
        <ContentHeader title={pageTitle}>
          <Input
            id="tech-radar-filter"
            type="search"
            placeholder="Filter"
            onChange={e => setSearchText(e.target.value)}
          />
        </ContentHeader>
        <Grid container spacing={3} direction="row" style={{ height: '95%' }}>
          <Grid item xs={12} style={{ height: '95%' }}>
            <ResizableComponent {...componentProps} searchText={searchText} />
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
}

function ResizableComponent(props: TechRadarPageProps) {
  const ref = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    setWidth(ref.current!.offsetWidth);
    setHeight(ref.current!.offsetHeight);
  }, []);
  // ratio is extracted from example
  const ratioHeight = width ? width * (800 / 1500) : 0;
  return (
    <div ref={ref} style={{ height: '100%' }}>
      {Boolean(width && height) && (
        <TechRadarComponent
          searchText={props.searchText}
          width={width}
          height={Math.min(ratioHeight, height)}
          {...props}
        />
      )}
    </div>
  );
}
