// responsible to fetch data from /tech-radar/data.json
import { CatalogClient } from '@backstage/catalog-client';

import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';

export class CustomCatalogClient extends CatalogClient {
  refeshGCPProviders = async () => {
    // @ts-expect-error
    return this.requestRequired('POST', `/gcp/refresh-all`);
  };
  refeshGithubProviders = async () => {
    // @ts-expect-error
    return this.requestRequired('POST', `/github/refresh-all`);
  };
}
