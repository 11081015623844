// https://github.com/backstage/backstage/blob/master/plugins/catalog/src/components/CatalogTable/CatalogTable.tsx

import { TableColumn, TableProps } from '@backstage/core-components';
import { CatalogTable, CatalogTableRow } from '@backstage/plugin-catalog';
import {
  useEntityList,
  useStarredEntities,
} from '@backstage/plugin-catalog-react';
import { Typography, withStyles } from '@material-ui/core';
import Star from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';
import React, { useMemo } from 'react';

/**
 * Props for {@link CatalogTable}.
 *
 * @public
 */
export interface CatalogTableProps {
  columns?: TableColumn<CatalogTableRow>[];
  actions?: TableProps<CatalogTableRow>['actions'];
  tableOptions?: TableProps<CatalogTableRow>['options'];
  subtitle?: string;
}
const YellowStar = withStyles({
  root: {
    color: '#f3ba37',
  },
})(Star);
/** @public */
export const CustomCatalogTable = (props: CatalogTableProps) => {
  const { filters } = useEntityList();
  const { isStarredEntity, toggleStarredEntity } = useStarredEntities();
  const defaultColumns: TableColumn<CatalogTableRow>[] = useMemo(() => {
    return [
      CatalogTable.columns.createTitleColumn({ hidden: true }),
      CatalogTable.columns.createNameColumn({
        defaultKind: filters.kind?.value,
      }),
      ...createEntitySpecificColumns(),
    ];

    function createEntitySpecificColumns(): TableColumn<CatalogTableRow>[] {
      switch (filters.kind?.value) {
        case 'user':
          return [];
        case 'domain':
        case 'system':
          return [CatalogTable.columns.createOwnerColumn()];
        case 'group':
        case 'template':
          return [CatalogTable.columns.createSpecTypeColumn()];
        case 'location':
          return [
            CatalogTable.columns.createSpecTypeColumn(),
            CatalogTable.columns.createSpecTargetsColumn(),
          ];
        default:
          return [
            CatalogTable.columns.createSystemColumn(),
            CatalogTable.columns.createOwnerColumn(),
            CatalogTable.columns.createSpecTypeColumn(),
          ];
      }
    }
  }, [filters.kind?.value]);

  const defaultActions: TableProps<CatalogTableRow>['actions'] = [
    ({ entity }) => {
      const isStarred = isStarredEntity(entity);
      const title = isStarred ? 'Remove from favorites' : 'Add to favorites';

      return {
        cellStyle: { paddingLeft: '1em' },
        icon: () => (
          <>
            <Typography variant="srOnly">{title}</Typography>
            {isStarred ? <YellowStar /> : <StarBorder />}
          </>
        ),
        tooltip: title,
        onClick: () => toggleStarredEntity(entity),
      };
    },
  ];

  return (
    <CatalogTable
      {...props}
      columns={defaultColumns}
      actions={defaultActions}
    />
  );
};
