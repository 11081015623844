import {
  createUnifiedTheme,
  genPageTheme,
  palettes,
  shapes,
} from '@backstage/theme';

export const brevoTheme = createUnifiedTheme({
  palette: {
    ...palettes.light, // Take everything from the default light theme, then change what you want
    primary: {
      main: '#0B996E',
    },
    secondary: {
      main: '#006A43', // Darker red
    },
    background: {
      default: '##FFFFFF',
    },
    navigation: {
      background: '#F9FFF6', // Lighter red background for the left-side panel
      indicator: '#0B996E', // Red color for the selected indicator
      selectedColor: '#1B1B1B', // White text color for the selected item
      color: '#1B1B1B', // Light gray text color for unselected items
      navItem: {
        hoverBackground: '#D7FEC8', // Darker red for the hover background
      },
    },
  },

  fontFamily:
    'Inter-Regular,Arial,sans-serif,ui-sans-serif,system-ui,-apple-system',
  defaultPageTheme: 'home',
  pageTheme: {
    home: genPageTheme({ colors: ['#0B996E', '#006A43'], shape: shapes.wave2 }),
    documentation: genPageTheme({
      colors: ['#BC4F2D', '#FFE7E0'],
      shape: shapes.wave2,
    }),
    tool: genPageTheme({ colors: ['#8c4351', '#343b58'], shape: shapes.round }),
    service: genPageTheme({
      colors: ['#8c4351', '#343b58'],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: ['#8c4351', '#343b58'],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: ['#8c4351', '#343b58'],
      shape: shapes.wave,
    }),
    other: genPageTheme({ colors: ['#8c4351', '#343b58'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#8c4351', '#343b58'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#8c4351', '#343b58'], shape: shapes.wave }),
  },
});
