import { HomePageStarredEntities, HomePageToolkit } from '@backstage/plugin-home';
import { Content, Page } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import {
  HomePageRequestedReviewsCard,
  HomePageYourOpenPullRequestsCard,
} from '@roadiehq/backstage-plugin-github-pull-requests';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import BuildIcon from '@material-ui/icons/Build';


const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    maxWidth: '60vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: '8px 0',
    borderRadius: '50px',
    margin: 'auto',
  },
}));


 const linkArray = [{
    url: 'https://storage.cloud.google.com/sib_client_config_stg/architecture.png',
    label: 'Global Architecture diagram',
    icon: <LibraryBooks />,
   },
   {
    url: 'https://app.diagrams.net/#G1E6lZYZF6fOqaq8TZiQQL7yyqNGgxYxVJ',
    label: 'Architecture diagram Draw Link',
   icon: <BuildIcon />,
   }]


export const DefaultTemplate = () => {
  const classes = useStyles();
  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <Grid container item xs={12} alignItems="center" direction="row">
              <HomePageSearchBar
                classes={{ root: classes.searchBar }}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <HomePageRequestedReviewsCard query="org:dtsl is:open is:pull-request review-requested:@me draft:false archived:false" />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageYourOpenPullRequestsCard query="org:dtsl is:open is:pull-request author:@me archived:false" />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageStarredEntities />
              </Grid>
              <Grid item xs={12} md={6}>
              <HomePageToolkit
                  tools={linkArray}
                   />
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
