import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';

import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  identityApiRef,
  fetchApiRef,
  discoveryApiRef,
  storageApiRef,
  errorApiRef,
} from '@backstage/core-plugin-api';
import { exploreApiRef } from '@backstage/plugin-explore';
import { techRadarApiRef } from '@backstage/plugin-tech-radar';
import { UserSettingsStorage } from '@backstage/plugin-user-settings';
import { SendinblueTechRadarApi } from './components/tech-radar/client';
import { CustomCatalogClient } from './clients/CustomCatalogClient';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

export const apis: AnyApiFactory[] = [
  ScmAuth.createDefaultApiFactory(),
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef, identityApi: identityApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  // TODO: provide some useful tools we use? explore-plugin
  createApiFactory({
    api: exploreApiRef,
    deps: {},
    factory: () => ({
      async getTools() {
        return { tools: [] };
      },
    }),
  }),
  createApiFactory({
    api: techRadarApiRef,
    deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
    factory: ({ discoveryApi, fetchApi }) => {
      return new SendinblueTechRadarApi({ discoveryApi, fetchApi });
    },
  }),
  createApiFactory({
    api: storageApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      errorApi: errorApiRef,
      fetchApi: fetchApiRef,
      identityApi: identityApiRef,
    },
    factory: deps => UserSettingsStorage.create(deps),
  }),
  createApiFactory({
    api: catalogApiRef,
    deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
    factory: ({ discoveryApi, fetchApi }) => {
      return new CustomCatalogClient({ discoveryApi, fetchApi });
    },
  }),
];
